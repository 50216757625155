.order-page {
  &__container {
    display: flex;
    flex-direction: row;
    gap: 35px;
    margin-top: 20px;
  }

  &__download-button {
    text-decoration: none;
    height: min-content;
  }

  &__basic-form-container {
    display: flex;
    flex-direction: column;
    width: min-content;
    height: min-content;
    min-width: 300px;
    gap: 30px;
    padding: 30px 10px 10px;
    border-radius: 8px;
    background-color: white;

    .input {
      min-width: 300px;
    }
  }

  &__construction-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-item: center;
  }

  &__order-button {
    width: min-content;
    height: min-content;
    gap: 10px
  }

  &__construction-title {
    font-size: 24px;
    color: #4f4f50;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  &__construction-item {
    background-color: white;
    display: flex;

    width: auto;
    flex-direction: row;
    gap: 10px;
    border-radius: 8px;
    cursor: pointer;
    padding: 24px;
    justify-content: space-between;
  }

  &__construction-container {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
}
