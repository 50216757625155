
.estimate-table-wrapper {
  margin-top: 10px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  overflow-x: auto;

  &__button {
    margin-top: 10px;
    margin-right: 30px;
  }

  &__download-button {
    text-decoration: none;
  }
}

.estimate-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

.estimate-table th,
.estimate-table td {
  border: 1px solid #ccc;
  padding: 8px 12px;
  text-align: left;
}

.estimate-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.estimate-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
