.ais-module {
  &__order-list {
    display: flex;
    flex-direction: column;
    gap: 10px
  }

  &__top-container {
    position: relative;
  }

  &__top-button {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.name-form {
  background-color: white;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 8px;
}
