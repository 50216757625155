.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 60px;
  margin-bottom: 50px;

  &__requisites-form {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  &__form-title {
    font-size: 22px;
    text-align: center;
    color: #737c8c;
  }

  &__column-form-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
  }

  &__row-form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__change-form {
    cursor: pointer;
    font-size: 10px;
    color: #0099cc;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 30px
  }
}
