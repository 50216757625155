.user-profile {
  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  &__label {
    font-weight: bold;
    color: #333;
  }

  &__value {
    color: #555;
  }
}
