.graphic-form {

  &__button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  &__bracing-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  &__bracing-item-image {
    width: 100%;
  }

  &__bracing-item {
    height: 40px;
    width: 40px;
  }

  &__form-text {
    display: flex;
    flex-direction: column;
    background-color: white;

  }

  &__cell-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .button-add {
      width: 20px;
      height: 50%;
    }
  }

  &__cell-bottom-field {
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .order-page {
    &__basic-form-container {
      margin-top: 10px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 50px;

  }

  &__button {
    height: min-content;
  }

  &__form-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__park-container {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    &_left {
      align-items: start;
    }

    &_right {
      align-items: end;
    }
  }

  &__park-line {
    background-color: #a42a2a;
    width: 100%;
    height: 20px;
    position: absolute;
  }

  &__park {
    height: 60px;
    width: 80px;

    &_perpendicular {
      border-left: 20px solid #a42a2a;
      border-right: 20px solid #a42a2a;

      &_left-top {
        transform: translateY(-40px);
      }

      &_right-top {
        transform: translateY(-40px);
      }
    }

    &_parallel {
      &_left-top {
        border-right: 20px solid #a42a2a;
        border-top: 20px solid #a42a2a;
        transform: translateY(-40px);
      }

      &_left-bottom {
        border-right: 20px solid #a42a2a;
        border-bottom: 20px solid #a42a2a;

      }

      &_right-top {
        border-left: 20px solid #a42a2a;
        border-top: 20px solid #a42a2a;
        transform: translateY(-40px);
      }

      &_right-bottom {
        border-left: 20px solid #a42a2a;
        border-bottom: 20px solid #a42a2a;
      }
    }
  }

  &__cell-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 5px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      margin-right: -100px;
      right: 100%;
      border-radius: 5px;
      background-color: #8a8ca4;
    }
  }

  &__cell-input {
    width: 100%;
    font-size: 10px;
  }

  &__cell-input-placeholder {
    font-size: 12px;
  }

  &__cell {
    width: 152px;
    background-color: #b9cfff;
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    &_active {
      background-color: #95abe0;
    }
  }
}
