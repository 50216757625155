
html,
body {
  height: 100%;
}

.app {
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f5;
}

.app-container {
  padding: 0 34px;
  min-height: 80vh;
}
