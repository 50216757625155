.header {
  background-color: #0099cc;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 20px;

  &__logo {
    width: 50px;
    cursor: pointer;
  }
}
