/* FormSlider.css */
.slide-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}
.step-content {
  position: absolute;
  display: flex;
  width: calc(100% - 68px);
  flex-direction: column;
  gap: 30px;
}
