.construction {
  &__form {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
  }


  &__radio-forms-row {
    display: flex;
    flex-direction: row;
    gap: 10px
  }
  &__button {
    margin-top: 20px;
    flex-direction: row-reverse;
    gap: 10px;
    margin-right: 30px;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
  }

  &__radio-group-title {
    font-size: 10px;
    color: #4f4f50;
  }
  &__container {
    display: flex;
    height: min-content;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
  }

  &__basic-form-container {
    display: flex;
    flex-direction: column;
    width: min-content;
    height: min-content;
    min-width: 300px;
    gap: 30px;
    padding: 30px 10px 10px;
    border-radius: 8px;
    background-color: white;

    .input {
      min-width: 300px;
    }
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__radio-forms {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__checkbox-container {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 10px;
    color: #4f4f50;
    align-items: center;
    width: 120px;
  }
  &__park-variant {
    display: flex;
    flex-direction: column;
    padding: 30px 10px 30px 10px;
    border-radius: 8px;
    height: min-content;
    width: max-content;
    gap: 30px;
    background-color: white;

    &_form {
      display: flex;
      gap: 20px
    }
  }

  &__profile-variant {
    display: flex;
    flex-direction: row;
    padding: 30px 10px 30px 10px;
    border-radius: 8px;
    height: min-content;
    width: max-content;
    gap: 30px;
    background-color: white;
    margin-top: 10px;
  }
}

.graphic-form {
  &__button {
    gap: 10px;
  }
}
